import * as React from "react";
import ReactPlayer from "react-player";
import { IVideoProps } from "./Video";

const VideoPlayer = (props: IVideoProps) => (
    <ReactPlayer
        className='react-player'
        width='100%'
        height='100%'
        url={props.videoUrl}
        controls={props.isShowControls}
        loop={props.isLoop}
        muted={props.isMuted}
    />
)

export default VideoPlayer