import "../../common/polyfills";
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Video from './Components/Video';
import MiniVideoPlayer from './Components/LittleVideoPlayer';

function MountVideo(props: any, el: Element) {
    const VideoComponent = props.isMini ? MiniVideoPlayer : Video
    ReactDOM.render(
        <VideoComponent {...props} />
        , el);
}

(window as any).MountVideo = MountVideo;


